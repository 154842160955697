var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bgWalletBlock moon-pay-token-select",
      class: _vm.open ? "open" : "",
    },
    [
      _c("div", { staticClass: "py-8" }, [
        _c(
          "div",
          { staticClass: "px-5 d-flex align-center" },
          [
            _c(
              "v-btn",
              {
                attrs: { icon: "" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.close.apply(null, arguments)
                  },
                },
              },
              [
                _c("v-icon", { attrs: { color: "textDark" } }, [
                  _vm._v("mdi-arrow-left"),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "ml-4 mew-heading-2 textDark--text" }, [
              _vm._v("Select Token"),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "px-8 mt-8" },
          [
            _c("mew-select", {
              staticClass: "mt-1",
              attrs: {
                items: _vm.fetchedNetworks,
                "filter-placeholder": "Select Network",
                label: "Network",
                "has-filter": "",
                "is-custom": "",
              },
              model: {
                value: _vm.selectedNetwork,
                callback: function ($$v) {
                  _vm.selectedNetwork = $$v
                },
                expression: "selectedNetwork",
              },
            }),
            _c("v-text-field", {
              staticClass: "mt-2",
              attrs: {
                outlined: "",
                label: "Search",
                "prepend-inner-icon": "mdi-magnify",
                "hide-details": "",
              },
              model: {
                value: _vm.searchValue,
                callback: function ($$v) {
                  _vm.searchValue = $$v
                },
                expression: "searchValue",
              },
            }),
            _vm.searchedCurrencyItems.length > 0
              ? _c(
                  "div",
                  { staticClass: "mt-5" },
                  _vm._l(_vm.searchedCurrencyItems, function (token, idx) {
                    return _c(
                      "div",
                      { key: idx },
                      [
                        token.name
                          ? _c(
                              "v-btn",
                              {
                                staticClass: "d-flex align-center py-6",
                                class:
                                  token.name == _vm.selectedCurrency.name
                                    ? "selected-button"
                                    : "",
                                attrs: {
                                  color: "buttonGrayLight",
                                  width: "100%",
                                  text: "",
                                  dpressed: "",
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.tokenSelected(token)
                                  },
                                },
                              },
                              [
                                _c("mew-token-container", {
                                  attrs: { size: "30px", img: token.img },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mew-heading-3 textDark--text ml-4",
                                  },
                                  [_vm._v(" " + _vm._s(token.name) + " ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "textDark--text ml-1" },
                                  [_vm._v("- " + _vm._s(token.subtext))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "textDark--text ml-auto" },
                                  [_vm._v(_vm._s(token.price))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "mt-5 mew-heading-4 ml-4" }, [
                  _vm._v("No tokens found"),
                ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }